<template>
  <div>
    <template v-if="loading">
      Cargando...
    </template>
    <template v-else>
      <template v-if="sectionEvaluations.length == 0">
        No hay evaluaciones planificadas.
      </template>
      <template v-else>
        <div class="row">
          <b-form-group
            label="Evaluación"
            label-cols="0"
            label-cols-md="3"
            label-cols-lg="4"
            class="my-0 py-0 w-100"
          >
            <b-form-select
              v-model="selected_evaluation_id"
              value-field="id"
              text-field="name"
              size="sm"
              class="mb-2"
            >
              <b-form-select-option
                v-for="evaluation in sectionEvaluations"
                :key="evaluation.id"
                :value="evaluation.id"
                >{{ evaluation.name }} -
                {{
                  getEvaluationInstrumentType(evaluation.evaluation_instrument)
                }} ({{getEvaluationStudyUnit(evaluation.study_unit)}})</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <template v-if="selectedEvaluation">
            <p>{{ selectedEvaluation.description }}</p>
          </template>
        </div>

        <template v-if="!selected_evaluation_id">
          Seleccione una evaluación.
        </template>
        <template v-else-if="rubric">
          <EvaluateRubric :rubric_id="rubric.id"></EvaluateRubric>
        </template>
        <template v-else-if="questionTest">
          <EvaluateQuestionTest
            :question_test_id="questionTest.id"
          ></EvaluateQuestionTest>
        </template>
        <template v-else> Esta evaluación no tiene instrumento. </template>
      </template>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Instrument",
  components: {
    EvaluateRubric: () =>
      import("@/components/evaluations/ObservationInstrument/EvaluateRubric"),
    EvaluateQuestionTest: () =>
      import("@/components/evaluations/Test/Evaluate/EvaluateQuestionTest"),
  },
  props: {
    evaluation_id: {
      type: Number,
    },
    Section: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected_evaluation_id: this.evaluation_id,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      questionTests: names.QUESTION_TESTS,
      users: names.USERS,
      instrumentTypes: names.EVALUATION_INSTRUMENTS,
      studyUnits: names.STUDY_UNITS,
    }),
    sectionEvaluations() {
      let list = this.evaluations.filter((x) => x.section == this.Section.id);
      return list.sort(function (a, b) {
        if (a.end_date > b.end_date) return 1;
        if (a.end_date < b.end_date) return -1;
        return 0;
      });
    },
    selectedEvaluation() {
      if (!this.selected_evaluation_id) return null;
      return this.evaluations.find((x) => x.id == this.selected_evaluation_id);
    },
    rubric() {
      if (!this.selectedEvaluation) return null;
      return this.rubrics.find(
        (x) => x.evaluation == this.selectedEvaluation.id
      );
    },
    questionTest() {
      if (!this.selectedEvaluation) return null;
      return this.questionTests.find(
        (x) => x.evaluation == this.selectedEvaluation.id
      );
    },
  },
  methods: {
    getEvaluationInstrumentType(id) {
      const type = this.instrumentTypes.find((x) => x.id == id);
      if (type) return type.value;
      return "-";
    },
    getEvaluationStudyUnit(id) {
      const study_unit = this.studyUnits.find((x) => x.id == id);
      if (study_unit) return study_unit.name;
      return "-";
    },
  },
  watch: {
    selected_evaluation_id: function(){
      this.$emit("changeEvaluationId", this.selected_evaluation_id);
    },
    evaluation_id() {
      this.selected_evaluation_id = this.evaluation_id;
    },
    selectedEvaluation() {
      if (this.selectedEvaluation.evaluation_instrument >= 5) {
        this.loading = true;
        this.$store
          .dispatch(names.FETCH_OBSERVATION_INSTRUMENTS, this.selectedEvaluation.id)
          .then(() => {
            this.loading = false;
          });
      } else if (this.selectedEvaluation.evaluation_instrument <= 4) {
        this.loading = true;
        this.$store
          .dispatch(names.FETCH_QUESTION_TESTS, this.selectedEvaluation.id)
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.instrument-container{
  margin-bottom: 1.5rem !important;
}
</style>